export const currency_mapping = {
    INR: '₹',
    USD: '$',
    EUR: '€',
    AUD: 'AU$',
    CAD: 'CA$',
    GBP: '£',
    CNY: '¥',
    COP: 'CO$',
    CRC: '₡',
    FJD: 'FJ$',
    NZD: 'NZ$',
    PHP: '₱',
    SGD: 'S$',
    GBP: '£',
    PKR: '₨',
    BDT: '৳',
    NPR: 'रू'
};
